import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-body pt-9 pb-0" }
const _hoisted_3 = { class: "d-flex flex-wrap flex-sm-nowrap mb-3" }
const _hoisted_4 = { class: "flex-grow-1" }
const _hoisted_5 = { class: "d-flex justify-content-between align-items-start flex-wrap mb-2" }
const _hoisted_6 = { class: "d-flex flex-column" }
const _hoisted_7 = { class: "d-flex align-items-center mb-2" }
const _hoisted_8 = { href: "#" }
const _hoisted_9 = { class: "svg-icon svg-icon-1 svg-icon-primary" }
const _hoisted_10 = { class: "d-flex flex-wrap fw-bold fs-6 mb-4 pe-2" }
const _hoisted_11 = {
  href: "#",
  class: "d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
}
const _hoisted_12 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_13 = {
  href: "#",
  class: "d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
}
const _hoisted_14 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_15 = {
  href: "#",
  class: "d-flex align-items-center text-gray-400 text-hover-primary mb-2"
}
const _hoisted_16 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_17 = { class: "d-flex my-4" }
const _hoisted_18 = {
  href: "#",
  class: "btn btn-sm btn-light me-2",
  id: "kt_user_follow_button"
}
const _hoisted_19 = { class: "svg-icon svg-icon-3 d-none" }
const _hoisted_20 = { class: "me-0" }
const _hoisted_21 = { class: "d-flex flex-wrap flex-stack" }
const _hoisted_22 = { class: "d-flex flex-column flex-grow-1 pe-8" }
const _hoisted_23 = { class: "d-flex flex-wrap" }
const _hoisted_24 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" }
const _hoisted_25 = { class: "d-flex align-items-center" }
const _hoisted_26 = { class: "svg-icon svg-icon-success me-2" }
const _hoisted_27 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" }
const _hoisted_28 = { class: "d-flex align-items-center" }
const _hoisted_29 = { class: "svg-icon svg-icon-3 svg-icon-danger me-2" }
const _hoisted_30 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" }
const _hoisted_31 = { class: "d-flex align-items-center" }
const _hoisted_32 = { class: "svg-icon svg-icon-3 svg-icon-success me-2" }
const _hoisted_33 = { class: "d-flex overflow-auto h-55px" }
const _hoisted_34 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap" }
const _hoisted_35 = { class: "nav-item" }
const _hoisted_36 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown3 = _resolveComponent("Dropdown3")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "me-7 mb-4" }, [
            _createElementVNode("div", { class: "symbol symbol-100px symbol-lg-160px symbol-fixed position-relative" }, [
              _createElementVNode("img", {
                src: "media/avatars/300-1.jpg",
                alt: "image"
              }),
              _createElementVNode("div", { class: "position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px" })
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _cache[0] || (_cache[0] = _createElementVNode("a", {
                    href: "#",
                    class: "text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                  }, "Max Smith", -1)),
                  _createElementVNode("a", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen026.svg" })
                    ])
                  ]),
                  _cache[1] || (_cache[1] = _createElementVNode("a", {
                    href: "#",
                    class: "btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3",
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#kt_modal_upgrade_plan"
                  }, "Upgrade to Pro", -1))
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("a", _hoisted_11, [
                    _createElementVNode("span", _hoisted_12, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com006.svg" })
                    ]),
                    _cache[2] || (_cache[2] = _createTextVNode(" Developer "))
                  ]),
                  _createElementVNode("a", _hoisted_13, [
                    _createElementVNode("span", _hoisted_14, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen018.svg" })
                    ]),
                    _cache[3] || (_cache[3] = _createTextVNode(" SF, Bay Area "))
                  ]),
                  _createElementVNode("a", _hoisted_15, [
                    _createElementVNode("span", _hoisted_16, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com011.svg" })
                    ]),
                    _cache[4] || (_cache[4] = _createTextVNode(" max@kt.com "))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("a", _hoisted_18, [
                  _createElementVNode("span", _hoisted_19, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr012.svg" })
                  ]),
                  _cache[5] || (_cache[5] = _createTextVNode(" Follow "))
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("a", {
                  href: "#",
                  class: "btn btn-sm btn-primary me-3",
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#kt_modal_offer_a_deal"
                }, "Hire Me", -1)),
                _createElementVNode("div", _hoisted_20, [
                  _cache[6] || (_cache[6] = _createElementVNode("button", {
                    class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary",
                    "data-kt-menu-trigger": "click",
                    "data-kt-menu-placement": "bottom-end",
                    "data-kt-menu-flip": "top-end"
                  }, [
                    _createElementVNode("i", { class: "bi bi-three-dots fs-3" })
                  ], -1)),
                  _createVNode(_component_Dropdown3)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("span", _hoisted_26, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr066.svg" })
                      ]),
                      _cache[8] || (_cache[8] = _createElementVNode("div", {
                        class: "fs-2 fw-bolder",
                        "data-kt-countup": "true",
                        "data-kt-countup-value": "4500",
                        "data-kt-countup-prefix": "$"
                      }, " 4500$ ", -1))
                    ]),
                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "fw-bold fs-6 text-gray-400" }, "Earnings", -1))
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("span", _hoisted_29, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr065.svg" })
                      ]),
                      _cache[10] || (_cache[10] = _createElementVNode("div", {
                        class: "fs-2 fw-bolder",
                        "data-kt-countup": "true",
                        "data-kt-countup-value": "75"
                      }, " 75 ", -1))
                    ]),
                    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "fw-bold fs-6 text-gray-400" }, "Projects", -1))
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("span", _hoisted_32, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr066.svg" })
                      ]),
                      _cache[12] || (_cache[12] = _createElementVNode("div", {
                        class: "fs-2 fw-bolder",
                        "data-kt-countup": "true",
                        "data-kt-countup-value": "60",
                        "data-kt-countup-prefix": "%"
                      }, " 60% ", -1))
                    ]),
                    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "fw-bold fs-6 text-gray-400" }, "Success Rate", -1))
                  ])
                ])
              ]),
              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "d-flex align-items-center w-200px w-sm-300px flex-column mt-3" }, [
                _createElementVNode("div", { class: "d-flex justify-content-between w-100 mt-auto mb-2" }, [
                  _createElementVNode("span", { class: "fw-bold fs-6 text-gray-400" }, "Profile Compleation"),
                  _createElementVNode("span", { class: "fw-bolder fs-6" }, "50%")
                ]),
                _createElementVNode("div", { class: "h-5px mx-3 w-100 bg-light mb-3" }, [
                  _createElementVNode("div", {
                    class: "bg-success rounded h-5px",
                    role: "progressbar",
                    style: {"width":"50%"},
                    "aria-valuenow": "50",
                    "aria-valuemin": "0",
                    "aria-valuemax": "100"
                  })
                ])
              ], -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("ul", _hoisted_34, [
            _createElementVNode("li", _hoisted_35, [
              _createVNode(_component_router_link, {
                to: "/crafted/account/overview",
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode(" Overview ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_36, [
              _createVNode(_component_router_link, {
                to: "/crafted/account/settings",
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => _cache[17] || (_cache[17] = [
                  _createTextVNode(" Settings ")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}